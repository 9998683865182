<template>
  <section class="FullBanner">
    <img src="../../assets/images/public/small_banner.png" alt="" class="small_banner" v-if="!bannerURL">

    <!-- Event -->
    <div class="banner_img_box" :style="{ backgroundImage: 'url(' + bannerURL + ')' }" v-if="bannerURL">
      <div class="container text_center">
        <div class="model_name">
          <h3>{{ moduleName }}</h3>
        </div>
        <div @click="$router.push({ path: btnJumpPageURL })" v-if="btnName && btnJumpPageURL" class="right_btn">
          <span>{{ btnName }}</span> <i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
    </div>

    <!-- 其他模块 -->
    <div class="banner_img_box" v-else>
      <div class="container text_center">
        <div class="model_name">
          <h3>{{ moduleName }}</h3>
        </div>
        <div @click="$router.push({ path: btnJumpPageURL })" v-if="btnName && btnJumpPageURL" class="right_btn">
          <span>{{ btnName }}</span> <i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
    </div>
    <slot name="customFill"></slot>
  </section>
</template>
<script>
  export default {
    props: {
      bannerURL: {
        type: String,
        required: false
      },
      moduleName: {
        type: String,
        required: true
      },
      btnName: {
        type: String
      },
      btnJumpPageURL: {
        type: String
      },
      btnReturn: {
        type: Boolean,
        default: false
      }
    },
    name: 'FullBanner',
    components: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    methods: {},
    created() { }
  }
</script>
<style src='' lang='scss' scoped>
  .FullBanner {
    margin-top: 75px;
    position: relative;

  @media (min-width: 600px) {
      .small_banner {
        position: absolute;
        left: 18%;
        height: 186px;
      }
      .banner_img_box {
        height: 186px;
        .container{
          height: 100%;
        }
      }
    }
    @media (max-width: 600px) {
      .small_banner {
        position: absolute;
        left: 10%;
        width: 20%;
        height: 160px;
      }
      .banner_img_box {
        height: 160px;
        .container{
          height: 100%;
        }
      }
    }

    .banner_img_box {
      // height: 186px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: rgb(66, 102, 176);

      .text_center {
        position: relative;
      }

      .model_name {
        // width: 156px;
        padding: 0 10px;
        height: 75px;
        border-radius: 5px;
        /* background-color: #fff; */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        >h3 {
          text-align: center;
          line-height: 78px;
          font-size: 32px;
          /* color: #333; */
          color: #fff;
        }
        @media (max-width: 520px) {
          h3 {
            font-size: 20px !important;
          }
        }
        @media (max-width: 420px) {
          h3 {
            line-height: 75px;
            font-size: 16px !important;
          }
        }
      }

      .left_btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 45px;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 18px;
      }

      .right_btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 45px;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 5px 5px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
        text-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);

        span {
          margin: 0 18px 0 23px;
        }

        i {
          margin-right: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .FullBanner {
      .model_name >h3 {
        font-size: 1.6rem !important;
        line-height: 45px;
      }
    }
  }
</style>