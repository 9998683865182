<template>
  <section class="my-course-card ">
    <el-card v-for="(item, index) in list" :key="index" class="mt-3">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="img-card">
            <img :src="item.image" alt="" />
          </div>
        </el-col>
        <el-col :span="18">
          <el-row>
            <el-col class="card-info" :span="18">
              <div>
                <h3 class="title">{{ item.title }}</h3>
                <span> {{ item.time }} </span>
              </div>
            </el-col>
            <el-col class="card-button" :span="6">
              <div>
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="jumpLine(item.id)"
                >
                  Continue
                </button>
                <button
                  style="background-color: #f1f1f1; border: none; color: #999"
                  type="button"
                  class="btn btn-secondary"
                >
                  Refund
                </button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <section class="skillDesc">
        <el-row type="flex" justify="between">
          <el-col :span="21">
            <div class="desc-tex">{{ item.description }}</div>
          </el-col>
          <el-col :span="3">
            <button
              style="color: #fff"
              type="button"
              class="btn btn-warning"
              @click="jumpLine(item.id)"
            >
              Upgrade
            </button>
          </el-col>
        </el-row>
      </section>
    </el-card>
  </section>
</template>
<script>
export default {
  name: '',
  components: {},
  data () {
    return {}
  },
  props: {
    list: {
      type: Array,
      default: [],
    }
  },
  created () { },
  computed: {},
  watch: {},
  methods: {
    jumpLine (id) {
      this.$router.push(`/course-detail?id=${id}&type=3`)
    }
  },
}
</script>
<style  lang='scss' scoped>
.my-course-card {
  ::v-deep .el-card__body {
    padding: 10px;
  }
  .img-card {
    width: 100%;
    height: 150px;
    > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-info {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      color: #333;
      font-size: 18px;
      padding: 10px 0;
    }

    span {
      color: #666;
    }
  }

  .card-button {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    > button {
      width: 100px;
      height: 35px;

    }
  }

  .skillDesc {
    margin-top: 10px;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 10px;

    .desc-text {
      color: #666;
    }
  }
}
</style>
