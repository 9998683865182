<template>
  <div class="MyCourse">
    <!-- 页面 header 通屏大图 -->
    <FullBanner
      btnReturn
      :bannerURL="$route.meta.img"
      :moduleName="$route.meta.name"
    >
      <template v-slot:customFill>
        <iq-card class="iq-card">
          <section class="container">
            <header class="w-100 headerContent">
              <div class="content">
                <el-row class="py-3">
                  <el-col :span="24">
                    <el-input
                      clearable
                      @clear="courseList"
                      @keyup.enter.native="courseList"
                      class="w-100"
                      placeholder="Search Course"
                      v-model="search"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="16">
                    <OrganazationSel
                      @getOrganizationParams="getOrganizationParams"
                    />
                  </el-col>
                </el-row>
              </div>
            </header>
            <div class="certificate">
              <div>
                <router-link :to="{ name: 'social.my-certificate' }">
                  <img
                    style="height: 20px"
                    src="../../assets/images/course/certificate.png"
                    alt=""
                  />
                  <span class="ml-2">My Certificate</span>
                </router-link>
              </div>
            </div>
          </section>
        </iq-card>
      </template>
    </FullBanner>

    <tab-content id="myTabContent-1">
      <tab-content-item
        :active="true"
        id="prerecording-two"
        aria-labelled-by="prerecording-tab-two"
      >
        <div class="container">
          <MyCourseItem :list="prereList" />
        </div>
      </tab-content-item>
    </tab-content>
  </div>
</template>
<script>
import FullBanner from "@/components/ComComponents/FullBanner";
import MyCourseItem from "./components/MyCourseItem";
import OrganazationSel from "@/components/ComComponents/OrganazationSel";

export default {
  name: "MyCourse",
  mounted() {
    this.courseList();
  },
  components: { FullBanner, MyCourseItem, OrganazationSel },
  data() {
    return {
      prereList: [],
      search: "",
      organization_id: "",
    };
  },
  watch: {},
  methods: {
    // 获取我的课程信息
    courseList() {
      this.$http
        .courseList({
          type: 1,
          page: 1,
          keyword: this.search,
          organization_id: this.organization_id,
          per_page: 12,
        })
        .then((res) => {
          if (res.status == 200) {
            this.prereList = res.data.data;
            // console.log(res.data);
          }
        });
    },
    getOrganizationParams(data) {
      let OrganData = JSON.parse(JSON.stringify(data));
      let level = OrganData.level.id;
      switch (level) {
        case 0: {
          // jci
          this.organization_id = OrganData.jci.id;
          this.courseList();
          break;
        }
        case 4: {
          // area
          this.organization_id = OrganData.area.id;
          this.courseList();
          break;
        }
        case 2: {
          // nation
          this.organization_id = OrganData.national.id;
          this.courseList();
          break;
        }
        case 3: {
          // region
          this.organization_id = OrganData.regional.id;
          this.courseList();
          break;
        }
        case 1: {
          // local
          this.organization_id = OrganData.local.id;
          this.courseList();
          break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.MyCourse {
  min-height: 100vh;
  .myGroupBtn {
    position: absolute;
    top: 0;
    right: 26%;
    width: 150px;
    height: 45px;
    opacity: 0.4;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px 0px 5px 5px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
    text-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
    span {
      margin-left: 27px;
    }
  }
  .headerContent {
    .content {
      padding-top: 28px;
      .searchBox {
        padding: 0 8px;
        position: relative;
        img {
          position: absolute;
          right: 15px;
          top: 50%;
          cursor: pointer;
        }
      }
      .selectBox {
        margin: 14px 0 50px;
      }
    }
  }
  .certificate {
    display: flex;
    justify-content: flex-end;
  }

  #myTab-two {
    margin-bottom: 0;
  }
}
</style>
