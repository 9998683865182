<template>
  <section class="OrganizationScreening">
    <el-row :gutter="10" class="d-flex flex-start">
      <!-- count -->

      <!-- Level -->
      <el-col>
        <el-select
          clearable
          filterable
          v-model="params.level"
          value-key="id"
          :placeholder="$t('Events.3e9@level')"
          @change="handleChangeLevel"
        >
          <el-option
            v-for="item in levelList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <!-- JCI -->
      <el-col  v-if="[0].includes(params.level.id)">
        <el-select
          clearable
          filterable
          v-model="params.jci"
          value-key="id"
          placeholder="JCI"
        >
          <el-option
            v-for="item in jciList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <!-- Area -->
      <el-col v-if="[4].includes(params.level.id)">
        <el-select
          clearable
          filterable
          v-model="params.area"
          value-key="id"
          placeholder="Area"
        >
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <!-- National -->
      <el-col v-if="[1, 2, 3].includes(params.level.id)">
        <el-select
          clearable
          filterable
          v-model="params.national"
          value-key="id"
          placeholder="National"
          @change="handleChangeNational"
        >
          <el-option
            v-for="item in nationalList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <!-- Regional -->
      <el-col v-if="[1, 3].includes(params.level.id) && isShow">
        <el-select
          clearable
          filterable
          v-model="params.regional"
          value-key="id"
          placeholder="Regional"
          @change="handleChangeRegional"
        >
          <el-option
            v-for="item in regionalList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
      <!-- Local -->
      <el-col v-if="[1].includes(params.level.id)">
        <el-select
          clearable
          filterable
          v-model="params.local"
          value-key="id"
          placeholder="Local"
        >
          <el-option
            v-for="item in localList"
            :key="item.id"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  data () {
    return {
      isShow: true,
      params: {
        level: {},
        jci: {},
        area: {},
        national: {},
        regional: {},
        local: {},
      },

      levelList: [
        { id: 0, name: "JCI" },
        { id: 4, name: "Area" },
        { id: 2, name: "National" },
        { id: 3, name: "Regional" },
        { id: 1, name: "Local" },
      ],
      jciList: [],
      areaList: [
        { id: 1, name: "Africa and the Middle East" },
        { id: 3, name: "America" },
        { id: 2, name: "Asia and the Pacific" },
        { id: 4, name: "Europe" },
      ],
      nationalList: [],
      regionalList: [],
      localList: [],
    };
  },
  computed: {},
  watch: {
    params: {
      deep: true,
      handler: function (newVal, oldV) {
        setTimeout(() => {
          this.$emit("getOrganizationParams", newVal);
        }, 500);
      },
    },
  },
  methods: {
    handleChangeLevel (newVal) {
      this.params.jci = {};
      this.params.area = {};
      this.params.national = {};
      this.params.regional = {};
      this.params.local = {};
      this.jciList = [];
      this.nationalList = [];
      this.regionalList = [];
      this.localList = [];
      this.isShow = true;
      if (newVal.id == 0) {
        this.getOrganizationsList(0, "", "JCI");
      } else if (newVal.id == 4) {
      } else if ([2, 3, 1].includes(newVal.id)) {
        this.getOrganizationsList(2, "", "National");
      }
    },
    handleChangeNational (item) {
      this.isShow = item.has_regions == 0 ? false : true;
      this.regionalList = [];
      this.localList = [];
      this.params.local = {};
      this.params.regional = {};
      if (item.has_regions) {
        // console.log(1);
        this.getOrganizationsList("", item.id, "Regional");
      } else {
        // console.log(2);
        this.getOrganizationsList("", item.id, "Local");
      }
    },
    handleChangeRegional (item) {
      this.localList = [];
      this.params.local = {};
      this.getOrganizationsList("", item.id, "Local");
    },
    // 获取组织列表
    async getOrganizationsList (type, parent_id, judgmentType) {
      let res = await this.$http.getOrganizationList({ type, parent_id });
      if (res.status == 200) {
        if (judgmentType === "JCI") {
          this.jciList = res.data;
        } else if (judgmentType === "National") {
          this.nationalList = res.data;
        } else if (judgmentType === "Regional") {
          this.regionalList = res.data;
        } else if (judgmentType === "Local") {
          this.localList = res.data;
        }
      }
    },
  },
  async mounted () {
    this.$bus.$on("getBusParentData", async (data) => {
      let newVal = JSON.parse(JSON.stringify(data));
      if (Object.keys(newVal).length > 0) {
        if (newVal.level.id == 0) {
          await this.getOrganizationsList(0, "", "JCI");
        }
        if ([2, 3, 1].includes(newVal.level.id)) {
          this.getOrganizationsList(2, "", "National");
        }
        if (newVal.national.id) {
          if (newVal.national.has_regions) {
            this.isShow = true;
            await this.getOrganizationsList("", newVal.national.id, "Regional");
          } else {
            this.isShow = false;
            await this.getOrganizationsList("", newVal.national.id, "Local");
          }
          // console.log(newVal);
        }
        if (newVal.regional.id) {
          await this.getOrganizationsList("", newVal.regional.id, "Local");
        }

        this.params = newVal;

        // console.log(this.params);
      }
    });
  },
  beforeDestroy () {
    this.$bus.$off("getBusParentData");
  },
};
</script>
<style src='' lang='scss' scoped>
::v-deep .el-date-editor--date .el-input__inner,
::v-deep .el-select .el-input__inner {
  height: 45px;
  border-radius: 11px;
}
.el-col .el-col-24{
 max-width:160px;
}
</style>